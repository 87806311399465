import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

export default function Footer() {
  return (
    <section id="footer" className="footer ">
      <div className="foot-head">
        <div className="head-element1">connect</div>
        <div className="head-element2 text-dark">with us.</div>
      </div>
      <div className="foot-body">
        <div className="fbody1 d-flex">
          <div className="services-f w-50">
            <div className="services-f-head">Services</div>
            <div className="services-f-body">
              <ul className="list-unstyled">
                <li>
                  <Link to="#" className="text-decoration-none text-dark">
                    Clothes Washing
                  </Link>
                </li>
                <li>
                  <Link to="#" className="text-decoration-none text-dark">
                    Iron / Steam
                  </Link>
                </li>
                <li>
                  <Link to="#" className="text-decoration-none text-dark">
                    Car washing
                  </Link>
                </li>
                <li>
                  <Link to="#" className="text-decoration-none text-dark">
                    Dry-cleaning
                  </Link>
                </li>
                <li>
                  <Link to="#" className="text-decoration-none text-dark">
                    Sneaker's Restoration
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="about-us w-50">
            <div className="about-us-head">About us</div>
            <div className="services-f-body">
              <ul className="list-unstyled">
                <li>
                  <Link to="/customer-support" className="text-decoration-none text-dark">
                    Customer Support
                  </Link>
                </li>
                <li>
                  <Link to="/terms-of-services" className="text-decoration-none text-dark">
                    Terms & Conditions
                  </Link>
                </li>
                <li>
                  <Link to="/privacy-policy" className="text-decoration-none text-dark">
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to="/cancellation-and-refund-policy" className="text-decoration-none text-dark">
                    Cancellation & Refund Policy
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="end d-flex">
      <div className="color-blob"></div>
        <div className="icon-link w-30 d-flex justify-content-around align-items-center">
          <button className="btn btn-light" onClick={() => window.open('https://twitter.com/Sneaco_serve')}>
            <i className="fab fa-twitter"></i>
          </button>
          <button className="btn btn-light" onClick={() => window.open('https://www.instagram.com/sneaco.in/')}>
            <i className="fab fa-instagram"></i>
          </button>
          <button className="btn btn-light">
            <i className="fab fa-youtube"></i>
          </button>
          <button className="btn btn-light" onClick={() => window.open('https://www.linkedin.com/company/100718721/admin/feed/posts/')}>
            <i className="fab fa-linkedin"></i>
          </button>
        </div>
        <div className="pre-icon w-35 text-center">Copyright 2024 © NeoSolve Ventures India Pvt Ltd. All rights reserved.</div>
      </div>
    </section>
  );
}
