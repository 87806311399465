import './App.css';
import About from './screens/About';
import Home from './screens/Home';
import Checkout from './screens/Checkout';
import TermsAndConditions from './policies/TermsAndCondition';
import PrivacyPolicy from './policies/PrivacyPolicy';
import RefundPolicy from './policies/RefundPolicy';
import CustomerSupport from './policies/CustomerSupport';
import Admin from './panels/admin/Admin';
import {VendorMain} from "./panels/vendor/VendorMain"
import { VendorLogin } from './panels/vendor/VendorLogin';
import { NotFound } from './components/NotFound';
import { SignUp } from './components/SignUp';
import { NewProfile } from './components/Profile';
import { PaymentSuccess } from './components/PaymentSuccess';
import { MyOrders } from "./components/MyOrders"
import { Cancel } from './components/Cancel';
import { useEffect } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { Routes, Route, BrowserRouter as Router } from "react-router-dom"


function App() {

  useEffect(() => {
    const auth = getAuth()
    const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        localStorage.setItem('token', authUser.accessToken)
        localStorage.setItem('uid', authUser.uid)
      }
    })
    return unsubscribe
  })

  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/profile" element={<NewProfile />} />
          <Route path='/paymentsuccess' element={<PaymentSuccess />} />
          <Route path='/cancel' element={<Cancel />} />
          <Route path='/myorders' element={<MyOrders />} />
          <Route path='/terms-of-services' element={<TermsAndConditions />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/customer-support' element={<CustomerSupport />} />
          <Route path='/cancellation-and-refund-policy' element={<RefundPolicy />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/vendor" element={<VendorLogin />} />
          <Route path="/main-vendor" element={<VendorMain />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
