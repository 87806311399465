
import "./termsandcondition.css"
import React from 'react';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const TermsAndConditions = () => {
    return (
        <>
            <Navbar />
            <div className='tac-div'>
                <h2 className='tac-h2'>Terms & Conditions</h2>
                <p className='tac-p'>
                    Welcome to Sneaco! By accessing our website and using our services, you agree to comply with and be bound by the following terms and conditions.
                </p>
                <h3 className='tac-h3'>1. Service Usage</h3>
                <p className='tac-p'>
                    Sneaco provides an online platform to facilitate the booking of laundry services. Users must use the services responsibly and adhere to the guidelines provided.
                </p>
                <h3 className='tac-h3'>2. Booking and Payments</h3>
                <p className='tac-p'>
                    Users can book laundry services through our platform. Payments must be made as per the specified rates. Refunds and cancellations are subject to our refund policy.
                </p>
                <h3 className='tac-h3'>3. User Account</h3>
                <p className='tac-p'>
                    Users are responsible for maintaining the confidentiality of their account credentials. Sneaco is not liable for any unauthorized access to user accounts.
                </p>
            </div >
            <Footer />
        </>
    );
};

export default TermsAndConditions;
