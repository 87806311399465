import "./paymentsuccess.css"
import React from 'react';
import Navbar from "./Navbar";
import Footer from "./Footer";

export const Cancel = () => {
    return (
        <>
            <Navbar />
            <div className="order-success-container">
                <div className="order-success-content">
                    <h2>Order Canceled!</h2>
                    <p>Your payment has been cancel.</p>
                    <img src={require("../images/cancel.jpeg")} alt="Company Logo" className="company-logo" />
                </div>
            </div>
            <Footer />
        </>
    );
};

