import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, OAuthProvider } from "firebase/auth"

const firebaseConfig = {
    apiKey: "AIzaSyBcdoz5PYuIxwEDVsqwzwFvKhpoGUiZU24",
    authDomain: "laundary-408417.firebaseapp.com",
    projectId: "laundary-408417",
    storageBucket: "laundary-408417.appspot.com",
    messagingSenderId: "285664263611",
    appId: "1:285664263611:web:a525f41961ad9780de589e"
  }

const app = initializeApp(firebaseConfig);
export const googleProvider = new GoogleAuthProvider()
export const appleProvider = new OAuthProvider('apple.com')
export const auth = getAuth(app)