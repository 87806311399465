import "./admin-insight.css"
import { ToastContainer } from 'react-toastify';
import { UserSection } from "./UserSection";
import { OrderSection } from "./OrderSection";
export function AdminInsight() {

    return (
        <div className="admin-insight">
            <UserSection />
            <OrderSection />
            <ToastContainer />
        </div>
    )
}