import "./paymentsuccess.css"
import React from 'react';
import Navbar from "./Navbar";
import Footer from "./Footer";

export const PaymentSuccess = () => {
    return (
        <>
            <Navbar />
            <div className="order-success-container">
                <div className="order-success-content">
                    <h2>Order Successful!</h2>
                    <p>Your order has been successfully processed. Kindly check your ordered email for the same.</p>
                    <img src={require("../images/success.webp")} alt="Company Logo" className="company-logo" />
                </div>
            </div>
            <Footer />
        </>
    );
};

