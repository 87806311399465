import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router";
import axios from "axios"

export function VendorLogin() {

    const navigate = useNavigate()

    const [vendorId, setVendorId] = useState('');
    const [password, setPassword] = useState('');

    const handleVendorIdChange = (event) => {
        setVendorId(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    useEffect(() => {
        if (localStorage.getItem('vendorId')) {
            navigate("/main-vendor")
        }
    })

    const handleSubmit = (event) => {
        event.preventDefault();
        axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/vendor/laundry-auth?userId=${localStorage.getItem('uid')}`, { laundryId: vendorId, laundryPassword: password }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        }).then((res) => {
            navigate("/main-vendor")
            localStorage.setItem('vendorId', `${res.data.laundry._id}`)
            localStorage.setItem('laundryId', `${res.data.laundry.laundryId}`)
        }).catch((error) => {
            console.log(error)
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else if (error.response.status === 404) {
                    toast.error(error.response.data.message);
                } else if (error.response.status === 401) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error.response.data, 456)
                    toast.error(error.response.data.message);
                }
            } else if (error.request) {
                toast.error("No response received. Please check your network connection.");
            } else {
                toast.error("An error occurred. Please try again later.");
            }
        })
        console.log('Vendor ID:', vendorId);
        console.log('Password:', password);
    };

    return (
        <>
            <Navbar />
            <div className="vendor-login-container" style={{ backgroundColor: '#f2f2f2', padding: '30px', borderRadius: '10px', maxWidth: '400px', margin: 'auto', marginTop: '100px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
                <h2 style={{ color: '#333', textAlign: 'center', marginBottom: '30px', fontSize: '34px', fontFamily: "600" }}>Vendor Login</h2>
                <form onSubmit={handleSubmit}>
                    <div style={{ marginBottom: '20px' }}>
                        <input
                            type="text"
                            placeholder="Vendor ID"
                            value={vendorId}
                            onChange={handleVendorIdChange}
                            style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', width: '100%', boxSizing: 'border-box', marginBottom: '10px' }}
                            required
                        />
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={handlePasswordChange}
                            style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', width: '100%', boxSizing: 'border-box', marginBottom: '10px' }}
                            required
                        />
                    </div>
                    <button type="submit" style={{ backgroundColor: '#00F39F', color: 'white', padding: '12px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer', width: '100%', fontSize: '16px' }}>Login</button>
                </form>
                <ToastContainer />
            </div>
        </>
    );
}
