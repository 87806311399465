import "./admin-insight.css"
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export function OrderSection() {
    const [data, setData] = useState()
    const [year, setYear] = useState(2024)
    const [month, setMonth] = useState(2)

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/admin/orders-by-week?userId=${localStorage.getItem('uid')}`, { year, month }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        }).then((res) => {
            console.log(res.data.weeklyOrdersArray);
            setData(res.data.weeklyOrdersArray)
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else if (error.response.status === 404) {
                    toast.error(error.response.data.message);
                } else if (error.response.status === 401) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error.response.data, 456)
                    toast.error(error.response.data.message);
                }
            } else if (error.request) {
                toast.error("No response received. Please check your network connection.");
            } else {
                toast.error("An error occurred. Please try again later.");
            }
        })
    }, [year, month])
    return (
        <div className="admin-insight-user-section">
            <div className="admin-insight-user-section-head">ORDER SECTION INSIGHT</div>
            <div className="admin-insight-user-section-graph">
                <div className="admin-insight-age-group">
                    <div className="admin-insight-head">Weekly Orders</div>
                    <input
                        className="admin-insight-input"
                        type="number"
                        placeholder="Month"
                        value={month}
                        onChange={(e) => setMonth(e.target.value)}
                        min="1"
                        max="12"
                    />
                    <input
                        className="admin-insight-input"
                        type="number"
                        placeholder="Year"
                        value={year}
                        onChange={(e) => setYear(e.target.value)}
                        min="2024"
                        max="2100"
                    />
                    <ResponsiveContainer width="100%" height={400}>
                        <LineChart data={data} stroke="#FFAE35" >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="week" tickFormatter={(value) => `Week-${value}`} />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="orders" stroke="#8884d8" activeDot={{ r: 8 }} />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    )
}