import React from 'react'
import location from "../../images/tracking3.jpeg";
import freeDelivery from "../../images/laundromats-1.png";
import oneDay from "../../images/24hr1.png";
import "./Features.css";

export const Features = () => {
    return (
        <div>
            <section id='features' className="features-coming-soon">
                <div className="features-coming-soon-head">Features that'll make you DROOL . . .</div>
                <div className="features-to-your-home-ins-data-data">Our prices don't need offers but Our services need you. </div>
                <div className="features-serving">
                    <div className="features-serving-ins">
                        <div className="features-serving-ins-data">Multiple Laundromats.</div>
                        <img src={freeDelivery} alt="money-and-phone" className="features-serving-ins-img" />
                    </div>
                    <div className="features-serving-ins">
                        <div className="features-serving-ins-data">One-Day Deliveries.</div>
                        <img src={oneDay} alt="wall-clock" className="features-serving-ins-img" />
                    </div>
                    <div className="features-serving-ins">
                        <div className="features-serving-ins-data">Real-Time Progress.</div>
                        <img src={location} alt="location" className="features-serving-ins-img" />
                    </div>
                </div>
            </section>
        </div>
    )
}
