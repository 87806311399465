 const laundries = [
    {
        location: { lat: 26.8625, lng: 75.8198 },
        laundryId: "0001A",
        laundryName: "Online laundry solutions Malviya nagar",
        laundryContact:"",
        laundryStreet:"",
        laundryPincode:"302017",
        services: [
            {
                name: "Wash + Iron",
                imageUrl: "wash+iron.png",
                clothes: [
                    { name: "Shirt", types: ["Regular", "Heavy"], price: { Regular: 15, Heavy: 18 },imgUrl:"hawaiian-shirt.png" },
                    { name: "T-Shirt", types: ["Nylon", "Regular", "Oversized"], price: { Nylon: 12, Regular: 16, Oversized: 20 },imgUrl:"tshirt.png" },
                    { name: "Jeans/ Pants", types: ["Regular", "Plus"], price: { Regular: 44, Plus: 56 },imgUrl:"jeans.png" },
                    { name: "Hoodie/ Sweatshirts", types: ["Regular", "Oversized"], price: { Regular: 44, Oversized: 64},imgUrl:"sweatshirt.png" },
                    { name: "Shorts/ Skirt", types: ["Regular", "Denim/ Cargo"], price: { Regular: 8, "Denim/ Cargo": 12 },imgUrl:"shorts.png"},
                    { name: "One-piece",types: ["Half-length", "Full-length"], price: { "Half-length": 40, "Full-length": 80 },imgUrl:"one-piece.png"},
                ],
            },
            {
                name: "Wash",
                imageUrl: "washingMachine.png",
                clothes: [
                    { name: "Shirt", types: ["Regular", "Heavy"], price: { Regular: 11, Heavy: 14 },imgUrl:"hawaiian-shirt.png" },
                    { name: "T-Shirt", types: ["Nylon", "Regular", "Oversized"], price: { Nylon: 9, Regular: 12, Oversized: 15 },imgUrl:"tshirt.png" },
                    { name: "Jeans/ Pants", types: ["Regular", "Plus"], price: { Regular: 33, Plus: 42 },imgUrl:"jeans.png" },
                    { name: "Hoodie/ Sweatshirts", types: ["Regular", "Oversized"], price: { Regular: 33, Oversized: 48},imgUrl:"sweatshirt.png" },
                    { name: "Shorts/ Skirt", types: ["Regular", "Denim/ Cargo"], price: { Regular: 6, "Denim/ Cargo": 9 },imgUrl:"shorts.png"},
                    { name: "One-piece",types: ["Half-length", "Full-length"], price: { "Half-length": 30, "Full-length": 60 },imgUrl:"one-piece.png"},
                ],
            },
            {
                name: "Steam Iron",
                imageUrl: "ironing.png",
                clothes: [
                    { name: "Shirt", types: ["Any"], price: { Any: 10 },imgUrl:"hawaiian-shirt.png" },
                    { name: "T-Shirt", types: ["Any"], price: { Any: 10 },imgUrl:"tshirt.png" },
                    { name: "Jeans/ Pants", types: ["Any"], price: { Any: 10 },imgUrl:"jeans.png" },
                    { name: "Hoodie/ Sweatshirts", types: ["Any"], price: { Any: 10 },imgUrl:"sweatshirt.png" },
                    { name: "One-piece", types: ["Any"], price: { Any: 10 },imgUrl:"one-piece.png"},
                ],
            },
            {
                name: "Dry Clean",
                imageUrl: "dry-cleaning.png",
                clothes: [
                    { name: "Shirt", types: ["Any"], price: { Any: 50},imgUrl:"hawaiian-shirt.png" },
                    { name: "T-Shirt", types: ["Any"], price: { Any: 50},imgUrl:"tshirt.png" },
                    { name: "Jeans/ Pants", types: ["Any"], price: { Any: 50},imgUrl:"jeans.png" },
                    { name: "Hoodie/ Sweatshirts", types: ["Regular", "Heavy"], price: { Regular: 100, Heavy: 150},imgUrl:"sweatshirt.png" },
                    { name: "Jacket", types: ["Light", "Heavy/ Leather"], price: {Light: 250, "Heavy/ Leather": 300},imgUrl:"jacket.png" },
                    { name: "One-piece", types: ["Any"], price: {Any: 200},imgUrl:"one-piece.png" },
                    { name: "Sari", types: ["Regular", "Embroidered"], price: {Regular: 200, Embroidered: 250},imgUrl:"sari.png" },
                    { name: "Suit", types: ["2-pc", "3-pc"], price: {"2-pc": 300, "3-pc": 350},imgUrl:"suit.png" },
                    { name: "Shoes", types: ["Full white", "Multi-Coloured"], price: { "Full white": 250, "Multi-Coloured": 200},imgUrl:"shoes.png"},
                    { name: "Blanket", types: ["Single", "Double"], price: { Single: 300 , Double: 400 },imgUrl:"blanket.png" },
                ],
            },
        ],
    },
    {
        location: { lat: 26.9090, lng: 75.7376 },
        laundryId: "0001B",
        laundryName: "Mr. Blue vaishali nagar",
        laundryContact:"",
        laundryStreet:"",
        laundryPincode:"302021",
        services: [
            {
                name: "Wash + Iron",
                imageUrl: "wash+iron.png",
                clothes: [
                    { name: "Shirt", types: ["Regular", "Heavy"], price: { Regular: 17, Heavy: 21 },imgUrl:"hawaiian-shirt.png" },
                    { name: "T-Shirt", types: ["Nylon", "Regular", "Oversized"], price: { Nylon: 14, Regular: 18, Oversized: 23 },imgUrl:"tshirt.png" },
                    { name: "Jeans/ Pants", types: ["Regular", "Plus"], price: { Regular: 49, Plus: 63 },imgUrl:"jeans.png" },
                    { name: "Hoodie/ Sweatshirts", types: ["Regular", "Oversized"], price: { Regular: 49, Oversized: 63},imgUrl:"sweatshirt.png" },
                    { name: "Shorts/ Skirt", types: ["Regular", "Denim/ Cargo"], price: { Regular: 9, "Denim/ Cargo": 14 },imgUrl:"shorts.png"},
                    { name: "One-piece",types: ["Half-length", "Full-length"], price: { "Half-length": 40, "Full-length": 80 },imgUrl:"one-piece.png"},
                ],
            },
            {
                name: "Wash",
                imageUrl: "washingMachine.png",
                clothes: [
                    { name: "Shirt", types: ["Regular", "Heavy"], price: { Regular: 11, Heavy: 14 },imgUrl:"hawaiian-shirt.png" },
                    { name: "T-Shirt", types: ["Nylon", "Regular", "Oversized"], price: { Nylon: 9, Regular: 12, Oversized: 15 },imgUrl:"tshirt.png" },
                    { name: "Jeans/ Pants", types: ["Regular", "Plus"], price: { Regular: 33, Plus: 42 },imgUrl:"jeans.png" },
                    { name: "Hoodie/ Sweatshirts", types: ["Regular", "Oversized"], price: { Regular: 33, Oversized: 48},imgUrl:"sweatshirt.png" },
                    { name: "Shorts/ Skirt", types: ["Regular", "Denim/ Cargo"], price: { Regular: 6, "Denim/ Cargo": 9 },imgUrl:"shorts.png"},
                    { name: "One-piece",types: ["Half-length", "Full-length"], price: { "Half-length": 30, "Full-length": 60 },imgUrl:"one-piece.png"},
                ],
            },
            {
                name: "Steam Iron",
                imageUrl: "ironing.png",
                clothes: [
                    { name: "Shirt", types: ["Any"], price: { Any: 15 },imgUrl:"hawaiian-shirt.png" },
                    { name: "T-Shirt", types: ["Any"], price: { Any: 15 },imgUrl:"tshirt.png" },
                    { name: "Jeans/ Pants", types: ["Any"], price: { Any: 15 },imgUrl:"sweatshirt.png" },
                    { name: "Hoodie/ Sweatshirts", types: ["Any"], price: { Any: 15 },imgUrl:"sweatshirt.png" },
                    { name: "One-piece", types: ["Any"], price: { Any: 15 },imgUrl:"one-piece.png"},
                ],
            },
            {
                name: "Dry Clean",
                imageUrl: "dry-cleaning.png",
                clothes: [
                    { name: "Shirt", types: ["Any"], price: { Any: 60},imgUrl:"hawaiian-shirt.png" },
                    { name: "T-Shirt", types: ["Any"], price: { Any: 50},imgUrl:"tshirt.png" },
                    { name: "Jeans/ Pants", types: ["Any"], price: { Any: 60},imgUrl:"jeans.png" },
                    { name: "Hoodie/ Sweatshirts", types: ["Regular", "Heavy"], price: { Regular: 100, Heavy: 150},imgUrl:"sweatshirt.png" },
                    { name: "Jacket", types: ["Regular", "Heavy/ Leather"], price: {Regular: 200, "Heavy/ Leather": 300},imgUrl:"jacket.png" },
                    { name: "Suit", types: ["2-pc", "3-pc"], price: {"2-pc": 300, "3-pc": 350},imgUrl:"suit.png" },
                    { name: "Men's Kurta", types: ["Regular", "Heavy"], price: {"Regular": 100, "Heavy": 140} ,imgUrl:"men-kurta.png"},
                    { name: "Women's Kurta", types: ["Regular", "Medium", "Heavy"], price: {"Regular": 100, "Medium": 150,"Heavy": 200},imgUrl:"women-kurta.png" },
                    { name: "One-piece", types: ["Any"], price: {Any: 200},imgUrl:"one-piece.png" },
                    { name: "Sari", types: ["Regular", "Embroidered"], price: {Regular: 150, Embroidered: 250},imgUrl:"sari.png" },
                    { name: "Towel", types: ["Hand-towel", "Bath-towel"], price: {"Hand-towel": 30, "Bath-towel": 50} ,imgUrl:"towel.png"},
                    { name: "Blanket", types: ["Single", "Double"], price: { Single: 300 , Double: 400 } ,imgUrl:"blanket.png"},
                    { name: "Shoes", types: ["Full white", "Multi-Coloured"], price: { "Full white": 250, "Multi-Coloured": 200},imgUrl:"shoes.png"},
                ],
            },
        ],
    },
  ];
  
  export default laundries