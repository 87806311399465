import Accordion from 'react-bootstrap/Accordion';
import "./vendor-main.css"
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';

export function Accordian({ orders }) {

    const changeStatus = (delivery, _id) => {
        axios.put(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/vendor/change-status?userId=${localStorage.getItem('uid')}`, { delivery, _id }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        }).then((res) => {
            toast.success(res.data.message);
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        }).catch((error) => {
            console.log(error)
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else if (error.response.status === 404) {
                    toast.error(error.response.data.message);
                } else if (error.response.status === 401) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error(error.response.data.message);
                }
            } else if (error.request) {
                toast.error("No response received. Please check your network connection.");
            } else {
                toast.error("An error occurred. Please try again later.");
            }
        })
    }
    const styles = {
        container: {
            margin: '20px',
            padding: '20px',
            border: '1px solid #ddd',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#fff',
            fontFamily: 'Arial, sans-serif',
        },
        header: {
            fontSize: '24px',
            fontWeight: 'bold',
            marginBottom: '20px',
        },
        section: {
            marginBottom: '20px',
        },
        subHeader: {
            fontSize: '18px',
            fontWeight: 'bold',
            marginBottom: '10px',
        },
        list: {
            listStyleType: 'none',
            padding: '0',
        },
        listItem: {
            marginBottom: '10px',
            padding: '10px',
            border: '1px solid #ddd',
            borderRadius: '5px',
            backgroundColor: '#f9f9f9',
            display: 'flex',
            justifyContent: 'space-between',
        }
    };
    return (
        <Accordion className='custom-accordion' flush style={{ width: "98%", marginTop: "10px" }}>
            {
                orders.map((order, key) => (
                    <Accordion.Item eventKey={key} style={{ border: "1px solid #A6A6A6", borderRadius: "15px", marginBottom: "10px", backgroundColor: order.delivery === "Pending" || order.delivery === "Delivered" ? "white" : order.delivery === "Dispatched" ? "#DBFFCA" : "#FFFB9A" }}>
                        <Accordion.Header className='custom-accordion-header'>
                            <div className="accordion-inside1">{key + 1}. {order.name}</div>
                            <div className="accordion-inside2">{order.service.category}</div>
                            <div className="accordion-inside3">
                                {
                                    order.delivery == "Delivered" ? (
                                        <div className="accordion-inside-btn1">Delivered</div>
                                    ) : (
                                        <>
                                            <div className="accordion-inside-btn1" onClick={() => changeStatus("Dispatched", order._id)}>Dispatched</div>
                                            <div className="accordion-inside-btn2" onClick={() => changeStatus("In Process", order._id)}>In Process</div>
                                        </>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div style={styles.container}>
                                <h2 style={styles.header}>Order Details</h2>
                                <div style={styles.section}>
                                    <h3 style={styles.subHeader}>Service -</h3>
                                    <ul style={styles.list}>
                                        {order.service.clothes && order.service.clothes.map((clothing, index) => (
                                            <li key={index} style={styles.listItem}>
                                                <span>{clothing.name}</span>
                                                <span><b>Quantity:</b> {clothing.quantity}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div style={styles.section}>
                                    <span><b>Total:</b> Rs {order.total}</span>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item >
                ))
            }
            <ToastContainer />
        </Accordion >
    );
}