// RefundPolicy.js

import React from 'react';
import "./refundpolicy.css"
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const RefundPolicy = () => {
    return (
        <>
            <Navbar />
            <div className='rp-div'>
                <h2 className='rp-h2'>Refund Policy</h2>
                <p className='rp-p'>
                    Our refund policy outlines the conditions under which refunds can be requested for the laundry services booked through our platform.
                </p>
                <h3 className='rp-h3'>1. Cancellation</h3>
                <p className='rp-p'>
                    Users can cancel bookings within a specified timeframe to be eligible for a refund. Late cancellations may not be eligible for a full refund.
                </p>
                <h3 className='rp-h3'>2. Service Issues</h3>
                <p className='rp-p'>
                    If you encounter any issues with the laundry service, please contact our customer support team. Refunds may be provided based on the nature of the issue.
                </p>
                <h3 className='rp-h3'>3. Refund Processing Time</h3>
                <p>
                    Refunds will be processed within 7 business days from the date of request.
                </p>
                <h3 className='rp-h3'>4. Pickup and delivery of clothes</h3>
                <p>
                    We aim to deliver your cleaned clothes within the agreed-upon timeframe. Any delays will be communicated to you in advance.
                    While we take utmost care in handling your clothes, please report any lost or damaged items immediately upon delivery. Our team will investigate and address the issue promptly.
                </p>
                {/* Add more sections as needed */}
            </div>
            <Footer />
        </>
    );
};

export default RefundPolicy;
