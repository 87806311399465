import React from "react";
import { Link } from "react-router-dom";
import "./about.css";
import rohit from "../images/rohit.png";
import sid from "../images/sid.webp";
import office from "../images/office.jpeg";
import iPhone from "../images/iPhone.webp";
import cscheme from "../images/cscheme.jpeg";
import truck from "../images/Truck.webp";
import bagpack from "../images/Bagpack.webp";
import delivery from "../images/delivery.webp";
import patrikaGate from "../images/patrikaGate.jpeg";
import vaishaliNagar from "../images/vaishaliNagar.jpeg";
import Footer from "../components/Footer";

export default function About() {
  return (
    <div>
      <div>
        {/* Header */}
        <header className="about-header">
          <div className="header-item1">A</div>
          <div className="header-item2">bout us.</div>
          <div className="header-item3">
            <Link className="home-link" to="/">
              home
            </Link>
          </div>
        </header>

        {/* Founders */}
        <section className="founders">
          <div className="founders-head">Founders . sneaco</div>

          <div className="founders-body">
            {/* Founder 1 */}
            <div
              className="body-1"
            >
              <img
                src={rohit}
                alt="founder-icon"
                style={{
                  objectFit: "cover",
                  borderRadius: "10px",
                  filter: "brightness(1.2)",
                }}
              />
              <div className="card-body">
                <h1 className="card-item1">Rohit Singh Tanwar</h1>
                <p className="card-item2">
                  Founder & CEO at Sneaco ventures.
                  <br />
                  Company: Neosolve Ventures India pvt ltd. <br />
                  B-tech MNIT, Jaipur <br />
                  Based in Jaipur
                </p>
                <div className="social-icons">
                  <button
                    className="btn-1"
                    onClick={() => window.open("https://twitter.com/ravar_m")}
                  >
                    <i
                      className="fa-brands fa-square-x-twitter"
                      style={{ fontSize: "30px", filter: "brightness(1.3)" }}
                    ></i>
                  </button>
                  <button
                    className="btn-1"
                    onClick={() =>
                      window.open(
                        "https://www.linkedin.com/in/rohit-singh-tanwar-625617219"
                      )
                    }
                  >
                    <i
                      className="fa-brands fa-linkedin-in"
                      style={{ fontSize: "25px" }}
                    ></i>
                  </button>
                  <button
                    className="btn-1"
                    onClick={() =>
                      window.open("https://www.instagram.com/ravar._/")
                    }
                  >
                    <i
                      className="fa-brands fa-instagram"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </button>
                </div>
              </div>
            </div>

            {/* Founder 2 */}
            <div
              className="body-1"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={sid}
                alt="founder-icon"
                style={{
                  width: "60%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "10px",
                }}
              />
              <div className="card-body">
                <h1 className="card-item1">Siddhant Khariwal</h1>
                <p className="card-item2">
                  Director & CTO at Sneaco ventures. <br />
                  Company: Neosolve Ventures India pvt ltd. <br />
                  B-tech Manipal University, Jaipur <br />
                  Based in Jaipur
                </p>
                <div className="social-icons">
                  <button
                    className="btn-1"
                    onClick={() =>
                      window.open("https://x.com/siddhantkhariw4?s=20")
                    }
                  >
                    <i
                      className="fa-brands fa-square-x-twitter"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </button>
                  <button
                    className="btn-1"
                    onClick={() =>
                      window.open(
                        "https://www.linkedin.com/in/siddhant-khariwal-258612204"
                      )
                    }
                  >
                    <i
                      className="fa-brands fa-linkedin-in"
                      style={{ fontSize: "25px" }}
                    ></i>
                  </button>
                  <button
                    className="btn-1"
                    onClick={() =>
                      window.open("https://www.instagram.com/siddhantkhariwal/")
                    }
                  >
                    <i
                      className="fa-brands fa-instagram"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Headquarters */}
        <section className="HQ">
          <div className="HQ-head">
            <div className="Hq-item1">He</div>
            <div className="Hq-item2">ad</div>
            <div className="Hq-item3">Quarters.</div>
            <div className="Hq-item4"></div>
          </div>

          <div className="hq-body">
            {/* Content A */}
            <div className="content-a">
              <img
                src={office}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "10px",
                }}
                alt=""
              />
              <div className="content-card">
                <h1
                  className="contentcard-item1"
                  style={{ paddingLeft: "10px", color: "white" }}
                >
                  NeoSolve Ventures India pvt ltd
                </h1>
                <p className="contentcard-item2" style={{ color: "white" }}>
                  E 28 A DHANSERI APARTMENT SUMITRA PATH, BANI PARK - 302016,
                  Jaipur, Rajasthan <br />
                  Telephone: 0141-3728xxx
                </p>
              </div>
            </div>
            {/* Content B */}
            {/* <div className="content-b"></div> */}
          </div>
        </section>

        {/* Operations */}
        <section className="operations">
          <div className="operations-head">Operations.</div>

          <div className="operation-body">
            {/* Operation Item 1 */}
            <div className="operation-item">
              <img
                className="item-img"
                style={{
                  height: "400px",
                  width: "300px",
                  borderRadius: "15px",
                  objectFit: "cover",
                }}
                src={iPhone}
                alt="Order"
              />
              <div className="bottom-text" style={{ color: "grey" }}>
                Order
              </div>
            </div>
            {/* Operation Item 2 */}
            <div className="operation-item">
              <img
                className="item-img"
                style={{
                  height: "400px",
                  width: "300px",
                  borderRadius: "15px",
                  objectFit: "cover",
                }}
                src={truck}
                alt="Pickup"
              />
              <div className="bottom-text" style={{ color: "black" }}>
                Pickup
              </div>
            </div>
            {/* Operation Item 3 */}
            <div className="operation-item">
              <img
                className="item-img"
                style={{
                  height: "400px",
                  width: "300px",
                  borderRadius: "15px",
                  objectFit: "cover",
                }}
                src={bagpack}
                alt="Packaging"
              />
              <div className="bottom-text" style={{ color: "grey" }}>
                Packaging
              </div>
            </div>
            {/* Operation Item 4 */}
            <div className="operation-item">
              <img
                className="item-img"
                style={{
                  height: "400px",
                  width: "300px",
                  borderRadius: "15px",
                  objectFit: "cover",
                }}
                src={delivery}
                alt="24hrs Delivery"
              />
              <div className="bottom-text" style={{ color: "black" }}>
                24hrs Delivery
              </div>
            </div>
          </div>
        </section>

        {/* Locations */}
        <section className="locations">
          <div className="locations-head">
            <div className="head-1"></div>
            Locations in Jaipur.
          </div>

          <div className="location-body">
            {/* Location Item 1 */}
            <div className="location-item">
              <img
                className="item-img"
                style={{
                  height: "400px",
                  width: "300px",
                  borderRadius: "15px",
                  objectFit: "cover",
                }}
                src={cscheme}
                alt="C-Scheme"
              />
              <div className="bottom-text">C-Scheme</div>
            </div>
            {/* Location Item 2 */}
            <div className="location-item">
              <img
                className="item-img"
                style={{
                  height: "400px",
                  width: "300px",
                  borderRadius: "15px",
                  objectFit: "cover",
                }}
                src={patrikaGate}
                alt="Malviya Nagar"
              />
              <div className="bottom-text">Malviya Nagar</div>
            </div>
            {/* Location Item 3 */}
            <div className="location-item">
              <img
                className="item-img"
                style={{
                  height: "400px",
                  width: "300px",
                  borderRadius: "15px",
                  objectFit: "cover",
                }}
                src={vaishaliNagar}
                alt="Vaishali Nagar"
              />
              <div className="bottom-text">Vaishali Nagar</div>
            </div>
            {/* Add similar items for other locations */}
          </div>
        </section>

        {/* More About Us */}
        {/* <section className="more">
          <img src={pixels} alt="founder-icon" className="more-background" />
          <div className="more-content">
            <div className="more-top">Sneaco.in</div>
            <div className="more-mid">
              <input
                type="email"
                className="more-input"
                placeholder="Enter mail to connect!"
              />
            </div>
            <div className="more-bottom">
              <div className="more-bottom-left">
              Copyright 2023 © NeoSolve Ventures Ltd. All rights reserved.
              </div>
              <div className="more-bottom-right"></div>
            </div>
          </div>
        </section> */}
      </div>
      <div><Footer /></div>
    </div>
  );
}
