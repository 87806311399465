import "./admin-insight.css"
import { useEffect, useState } from "react"
import { toast } from 'react-toastify';
import axios from "axios"
import { ResponsiveContainer, AreaChart, XAxis, YAxis, Area, Tooltip, CartesianGrid } from "recharts"

export function UserSection() {
    const [data, setData] = useState()
    useEffect(() => {
        axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/admin/age-group?userId=${localStorage.getItem('uid')}`, {}, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        }).then((res) => {
            console.log(res.data.data);
            setData(res.data.data)
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else if (error.response.status === 404) {
                    toast.error(error.response.data.message);
                } else if (error.response.status === 401) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error.response.data, 456)
                    toast.error(error.response.data.message);
                }
            } else if (error.request) {
                toast.error("No response received. Please check your network connection.");
            } else {
                toast.error("An error occurred. Please try again later.");
            }
        })
    }, [])
    return (
        <div className="admin-insight-user-section">
            <div className="admin-insight-user-section-head">USER SECTION INSIGHT</div>
            <div className="admin-insight-user-section-graph">
                <div className="admin-insight-age-group">
                    <div className="admin-insight-head">AGE GROUP</div>
                    <ResponsiveContainer width="100%" height={380} className="admin-insight-graph">
                        <AreaChart data={data} margin={{ right: 8, left: -35, bottom: 50 }}>
                            <defs>
                                <linearGradient id="color" x1="0" x2="0" y1="0" y2="1">
                                    <stop offset="0%" stopColor="#FFAE35" stopOpacity={0.4} />
                                    <stop offset="75%" stopColor="#FFAE35" stopOpacity={0.05} />
                                </linearGradient>
                            </defs>
                            <CartesianGrid stroke="#ccc" strokeDasharray="10 10" />
                            <Area type="monotone" dataKey="count" stroke="#FFAE35" fill="url(#color)" />
                            <XAxis dataKey="age" label={{ value: 'Age', fontSize: 25, color: "black", fontFamily: 'Montserrat', position: 'insideBottom', textAnchor: 'middle', dy: 25 }} tick={{ fontSize: 14, fontWeight: 600, fontFamily: 'Montserrat' }} />
                            <YAxis dataKey="count" tick={{ fontSize: 14, fontWeight: 600, fontFamily: 'Montserrat' }} />
                            <Tooltip />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
                <div className="admin-insight-age-group">
                    <div className="admin-insight-head">AGE GROUP</div>
                    <ResponsiveContainer width="100%" height={380} className="admin-insight-graph">
                        <AreaChart data={data} margin={{ right: 8, left: -35, bottom: 50 }}>
                            <defs>
                                <linearGradient id="color" x1="0" x2="0" y1="0" y2="1">
                                    <stop offset="0%" stopColor="#FFAE35" stopOpacity={0.4} />
                                    <stop offset="75%" stopColor="#FFAE35" stopOpacity={0.05} />
                                </linearGradient>
                            </defs>
                            <CartesianGrid stroke="#ccc" strokeDasharray="10 10" />
                            <Area type="monotone" dataKey="count" stroke="#FFAE35" fill="url(#color)" />
                            <XAxis dataKey="age" label={{ value: 'Age', fontSize: 25, color: "black", fontFamily: 'Montserrat', position: 'insideBottom', textAnchor: 'middle', dy: 25 }} tick={{ fontSize: 14, fontWeight: 600, fontFamily: 'Montserrat' }} />
                            <YAxis dataKey="count" tick={{ fontSize: 14, fontWeight: 600, fontFamily: 'Montserrat' }} />
                            <Tooltip />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    )
}