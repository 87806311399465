import "./home.css";
import React from "react";
import axios from "axios";
import { Container, Row, Col, Button, Form, Modal } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Services } from "../components/homeComponents/Services";
import { Features } from "../components/homeComponents/Features";
import { Cities } from "../components/homeComponents/Cities";
import laundries from "../components/homeComponents/laundaryData";
import wash from "../images/clotheIcons/washingMachine.png";
import iron from "../images/clotheIcons/ironing.png";
import washIron from "../images/clotheIcons/wash+iron.png";
import dryClean from "../images/clotheIcons/dry-cleaning.png";
import shirt from "../images/clotheIcons/hawaiian-shirt.png";
import tshirt from "../images/clotheIcons/tshirt.png";
import jeans from "../images/clotheIcons/jeans.png";
import hoodie from "../images/clotheIcons/sweatshirt.png";
import blanket from "../images/clotheIcons/blanket.png";
import onePiece from "../images/clotheIcons/dress.png";
import shorts from "../images/clotheIcons/shorts.png";
import jacket from "../images/clotheIcons/jacket.png";
import shoes from "../images/clotheIcons/sneakers.png";
import sari from "../images/clotheIcons/sari5.png";
import suit from "../images/clotheIcons/suits.png";
import towel from "../images/clotheIcons/towel3.png";
import kurta from "../images/clotheIcons/kurta.png";
import wkurta from "../images/clotheIcons/wkurta.png";
import bucket from "../images/bucket.png";
import delivery2 from "../images/delivery2.png";
import playstore from "../images/playstore-banner.png";
import playstore3 from "../images/playstore-banner3.png";
import { Link, useNavigate } from "react-router-dom";
import homeBanner from "../images/web-banner3.jpeg";
import appstore from "../images/appstore-image.png";
import playstore2 from "../images/playstore-image.png";
// import { CartAction } from "../redux/action/counterAction";
// import { useSelector, useDispatch } from "react-redux"

function Home() {
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [inServiceArea, setInServiceArea] = useState(null);
  const [laundryData, setLaundryData] = useState({ services: [] });
  const [availableServices, setAvailableServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedButton, setSelectedButton] = useState(null);
  const [selectedCloth, setSelectedCloth] = useState("");
  const [clothQuantities, setClothQuantities] = useState({});
  const [cart, setCart] = useState([]);
  const [basketCount, setBasketCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [pincode, setPincode] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const iconClothMapping = {
    Shirt: shirt,
    "T-Shirt": tshirt,
    "Jeans/ Pants": jeans,
    "Hoodie/ Sweatshirts": hoodie,
    Jacket: jacket,
    Blanket: blanket,
    "One-piece": onePiece,
    "Shorts/ Skirt": shorts,
    Shoes: shoes,
    Sari: sari,
    Suit: suit,
    "Men's Kurta": kurta,
    "Women's Kurta": wkurta,
    Towel: towel,
  };

  useEffect(() => {
    const user = localStorage.getItem("token");
    if (user) {
      setToken(user);
    }
  }, []);
  useEffect(() => {
    const storedBasketCount = parseInt(localStorage.getItem("basketCount"));
    if (storedBasketCount < 0) {
      storedBasketCount = 0;
    }
    if (!isNaN(storedBasketCount)) {
      setBasketCount(storedBasketCount);
    }
  }, []);

  const handleOrderNow = () => {
    setShowModal(true); // Show modal when "Order Now" button is clicked
  };

  const handlePincodeSubmit = () => {
    if (pincodeIsValid(pincode)) {
      setPincode(pincode);
      const nearestLaundry = findLaundryByPincode(pincode);
      if (nearestLaundry) {
        setInServiceArea(true);
        setLaundryData(nearestLaundry);
        setAvailableServices(
          nearestLaundry.services.map((service) => service.name)
        );
        console.log("nearest laundry: ", nearestLaundry);
        // localStorage.setItem('modalShown', 'true');
        const selectServiceSection = document.getElementById(
          "select-service-section"
        );
        if (selectServiceSection) {
          selectServiceSection.scrollIntoView({ behavior: "smooth" });
        }
      } else {
        setInServiceArea(false);
        // localStorage.setItem('modalShown', 'false');
        setPincodeError("Laundry not available for this pincode");
        const selectServiceSection = document.getElementById(
          "select-service-section"
        );
        if (selectServiceSection) {
          selectServiceSection.scrollIntoView({ behavior: "smooth" });
        }
      }
      setShowModal(false);
      // Proceed with displaying relevant information
    } else {
      setPincodeError("Invalid pincode. Please try again.");
    }
  };

  const pincodeIsValid = (enteredPincode) => {
    return enteredPincode.length === 6;
  };
  const findLaundryByPincode = (pincode) => {
    return laundries.find((laundry) => laundry.laundryPincode === pincode);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleServiceButtonClick = (service) => {
    setSelectedButton(service);
    setSelectedService(service);
    setSelectedCloth("");
  };

  const handleClothQuantityChange = (clothName, type, price, quantity) => {
    // console.log(`Cloth Quantity Change: ${clothName} - ${type} - ${price} - ${quantity}`);

    setClothQuantities((prevQuantities) => ({
      ...prevQuantities,
      [clothName]: {
        ...prevQuantities[clothName],
        [type]: {
          price: price,
          quantity: quantity,
        },
      },
    }));
  };

  const addToCart = async () => {
    const selectedClothes = [];

    Object.keys(clothQuantities).forEach((clothName) => {
      const clothDetails = clothQuantities[clothName];

      Object.keys(clothDetails).forEach((type) => {
        const cloth = clothDetails[type];

        selectedClothes.push({
          name: `${type} ${clothName}`,
          price: parseInt(cloth.price),
          quantity: parseInt(cloth.quantity),
        });
      });
    });
    const requestBody = {
      category: selectedService,
      clothes: selectedClothes,
    };
    console.log("Request Body:", requestBody);

    const totalClothes = Object.keys(clothQuantities).reduce(
      (total, clothName) => {
        const clothDetails = clothQuantities[clothName];

        Object.keys(clothDetails).forEach((type) => {
          total += clothDetails[type].quantity;
        });

        return total;
      },
      0
    );
    const hoodieQuantities = clothQuantities["Hoodie/ Sweatshirts"];
    const totalHoodieQuantity = Object.values(hoodieQuantities ?? {}).reduce(
      (total, cloth) => total + (cloth.quantity || 0),
      0
    );

    if (selectedService === "Wash + Iron") {
      // console.log("Cloth quantities regular: ", clothQuantities["Hoodie/ Sweatshirts"]?.Regular.quantity);
      // console.log("Cloth quantities oversized: ", clothQuantities["Hoodie/ Sweatshirts"]?.Oversized.quantity);
      if (totalHoodieQuantity > 1) {
        if (totalHoodieQuantity < 3) {
          toast.error(
            "Please select at least 3 Hoodie/ Sweatshirts for this service."
          );
          return;
        }
      } else {
        if (totalClothes < 7) {
          toast.error(
            "Please select at least 7 clothes for service Wash + Iron"
          );
          return;
        }
      }
    }
    if (selectedService === "Wash") {
      if (totalHoodieQuantity > 1) {
        if (totalHoodieQuantity < 3) {
          toast.error(
            "Please select at least 3 Hoodie/ Sweatshirts for this service."
          );
          return;
        }
      } else {
        if (totalClothes < 8) {
          toast.error("Please select at least 8 clothes for service Wash");
          return;
        }
      }
    }
    if (selectedService === "Steam Iron") {
      if (totalClothes < 12) {
        toast.error("Please select at least 12 clothes for service Iron");
        return;
      }
    }

    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/service/add-service`,
        {
          requestBody,
          userId: `${localStorage.getItem("uid")}`,
          laundryId: laundryData.laundryId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        toast.success(response.data.message);

        // const selectedClothes = Object.keys(clothQuantities).filter(
        //   (clothName) => clothQuantities[clothName] > 0
        // );
        // console.log("selected clothes: ", selectedClothes, selectedClothes.length);
        if (selectedClothes.length > 0) {
          const updatedCart = [
            ...cart,
            ...selectedClothes.map((clothName) => ({
              service: selectedService,
              cloth: clothName,
              quantity: clothQuantities[clothName],
            })),
          ];
          // console.log("update cart: ", updatedCart);
          setCart(updatedCart);
          // setBasketCount(updatedCart.length);
          const updatedBasketCount = basketCount + selectedClothes.length;
          localStorage.setItem("basketCount", updatedBasketCount);
          setBasketCount(updatedBasketCount);
          setSelectedCloth("");
          setClothQuantities({ ...clothQuantities, [selectedCloth]: 0 });
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          if (error.response.status === 400) {
            toast.error(error.response.data.message);
          } else if (error.response.status === 500) {
            toast.error(error.response.data.message);
          } else if (error.response.status === 404) {
            toast.error(error.response.data.message);
          } else if (error.response.status === 401) {
            toast.error(error.response.data.message);
            setTimeout(() => {
              navigate("/signup");
            }, 2000);
          }
        } else if (error.request) {
          toast.error(
            "No response received. Please check your network connection."
          );
        } else {
          toast.error("An error occurred. Please try again later.");
        }
      });
  };

  // Bucket Quantity using Redux & Backend !
  // const dispatch = useDispatch()
  // useEffect(() => {
  //   dispatch(CartAction());
  // })
  // const cartCount = useSelector(state => state.cartCount)

  return (
    <div className="home">
      <Navbar />
      <Container fluid className="d-flex justify-content-around align-items-center">
  <div className="home-banner-container">
    <div className="content-container">
      <div className="additional-text">
        <p
          style={{
            display: "inline-block",
            color: "#56bf7e",
          }}
        >
          ORDER.
        </p>
        <p
          style={{
            display: "inline-block",
            fontSize: "16px",
            fontWeight: "bold",
            lineHeight: "22.5px",
          }}
        >
          With our new app
        </p>
      </div>
      <div className="service-text">
        <p>Dry clean | Laundry | Shoes</p>
        <p>Delivered within 24hrs.</p>
      </div>
      <div className="app-download-container">
        <div className="app-download">
          <a href="https://play.google.com/store/apps/details?id=com.neosolve.sneaco" target="_blank" rel="noopener noreferrer">
            <img src={playstore2} alt="Play Store" />
          </a>
        </div>
        <div className="app-download app-store">
          <a href="#">
            <img src={appstore} alt="App Store" />
          </a>
        </div>
      </div>
      {/* <div className="order-pickup-delivery">
        <p>Order -----</p>
        <p>Pickup -----</p>
        <p>Delivery</p>
      </div> */}
      <Button
        onClick={handleOrderNow}
        className="order-now-btn"
        style={{
          backgroundColor: "#56bf7e",
          border: "2px solid rgba(255, 255, 255, 1)",
          fontWeight: "bold",
          fontSize: "16px",
          letterSpacing: "0px",
          width: "330px",
          height: "60px",
        }}
      >
        Order on website
      </Button>
    </div>
    <div className="banner-image">
      <img src={homeBanner} alt="Delivery Banner" className="home-banner" />
    </div>
  </div>
</Container>



          {/* Start of modal */}

      <Modal show={showModal} onHide={handleCloseModal} backdrop="static">
      <Modal.Header className="modal-header">
  <div className="row">
    <div className="col">
      {/* Image */}
      <img
        src={playstore3}
        alt="Delivery"
        className="playstore-banner"
        style={{ width: "85%" }}
      />
    </div>
    <div className="col-auto">
      {/* Close Button */}
      <button
        type="button"
        className="btn-close"
        aria-label="Close"
        onClick={handleCloseModal}
      ></button>
    </div>
  </div>
  {/* Remaining content */}
  <div>
    <h2 className="modal-title"> Check delivery at your location.</h2>
    <p
      style={{
        textAlign: "center",
        marginTop: 10,
        fontWeight: 500,
        fontSize: 14,
      }}
    >
      Please enter your pincode and let us know...
    </p>
  </div>
</Modal.Header>


        <Modal.Body>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={delivery2}
              alt="delivery man"
              className="icon"
              style={{ marginBottom: "17px" }}
            />
            <p style={{ fontWeight: 500, fontSize: 14, marginLeft: 5 }}>
              Do we deliver at your place?
            </p>
          </div>
          <input
            type="number"
            placeholder="Enter your pincode"
            value={pincode}
            onChange={(e) => setPincode(e.target.value)}
          />
          {pincodeError && <p className="text-danger">{pincodeError}</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handlePincodeSubmit}>
            Check
          </Button>
        </Modal.Footer>
      </Modal>
      <Container fluid>
        <Row className="home-service-selector" id="select-service-section">
          {inServiceArea && (
            <>
              <Col xs={12} className="mb-3 mt-3">
                <h2 className="text-center mb-3">Select Service</h2>
                <div className="service-buttons d-flex justify-content-between w-100">
                  {availableServices.map((service, index) => {
                    const matchingLaundry = laundries.find((laundry) => {
                      const matchingService = laundry.services.find(
                        (s) => s.name === service
                      );
                      return matchingService !== undefined;
                    });
                    if (matchingLaundry) {
                      const iconMapping = {
                        Wash: wash,
                        "Steam Iron": iron,
                        "Wash + Iron": washIron,
                        "Dry Clean": dryClean,
                      };
                      return (
                        <button
                          key={index}
                          className={`service-button ${
                            selectedButton === service ? "selected" : ""
                          }`}
                          onClick={() => handleServiceButtonClick(service)}
                        >
                          <img
                            src={iconMapping[service]}
                            alt={`${service} icon`}
                            className="icon"
                          />
                          {service}
                        </button>
                      );
                    }
                    return null;
                  })}
                </div>
              </Col>

              <Col xs={12} className="mb-3 mt-3">
                <h2 className="text-center mb-3">Select Clothes Quantity</h2>
                <div className="cloth-quantities d-flex flex-wrap justify-content-around w-80">
                  {selectedService &&
                    laundryData.services
                      ?.find((s) => s.name === selectedService)
                      ?.clothes.map((cloth, index) => (
                        <div key={index} className="cloth-quantity">
                          <div className="cloth-info mb-2">
                            <p className="cloth-name">
                              <img
                                src={iconClothMapping[cloth.name]}
                                alt={`${cloth.name} icon`}
                                className="icon"
                              />
                              {cloth.name}
                            </p>
                            <div className="type-info">
                              {cloth.types &&
                                cloth.types.map((type, typeIndex) => (
                                  <div
                                    key={typeIndex}
                                    className="type-quantity"
                                  >
                                    <span>{type} </span>
                                    <span className="type-price">
                                      {cloth.price[type]} Rs.
                                    </span>
                                    <Form.Control
                                      type="number"
                                      placeholder="No."
                                      min="0"
                                      value={
                                        clothQuantities[
                                          `${cloth.name} ${type} ${cloth.price[type]} ${selectedService}`
                                        ] === 0
                                          ? ""
                                          : clothQuantities[
                                              `${cloth.name} ${type} ${cloth.price[type]} ${selectedService}`
                                            ]
                                      }
                                      onChange={(event) =>
                                        handleClothQuantityChange(
                                          `${cloth.name}`,
                                          `${type}`,
                                          `${cloth.price[type]}`,
                                          parseInt(event.target.value) || 0
                                        )
                                      }
                                    />
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      ))}
                </div>
              </Col>
              {/* Fourth row add to cart and basket button */}
              <Col xs={12} className="mb-3 mt-3">
                <div className="d-flex justify-content-evenly align-items-center">
                  <Button
                    id="addToCartBtn"
                    onClick={addToCart}
                    className="mr-3"
                  >
                    Add to Cart
                  </Button>
                  <Link className="link2" to="/checkout">
                    <img
                      className="bucketIcon"
                      src={bucket}
                      alt="bucket icon"
                    />
                    {/* Bucket <span id="basketCount">{basketCount}</span> */}
                    Bucket <span id="basketCount">1</span>
                  </Link>
                </div>
              </Col>
            </>
          )}
          {inServiceArea === false && (
            <p
              style={{
                marginTop: 20,
                paddingTop: 20,
                textAlign: "center",
                justifyContent: "center",
                fontWeight: "bold",
                fontSize: "1.2em",
              }}
            >
              Sorry, we are not available in your area as of now.
            </p>
          )}
        </Row>
      </Container>
      {/* <a href="https://play.google.com/store/apps/details?id=com.neosolve.sneaco" target="_blank" rel="noopener noreferrer">
        <img
          src={playstore}
          alt="playstore banner"
          className="playstore-banner"
        />
      </a> */}
      <Services />
      <Features />
      <Cities />
      <Footer />
      <ToastContainer />
    </div>
  );
}

export default Home;
