import React, { useEffect, useState } from 'react';
import axios from "axios"
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { Modal, Button } from 'react-bootstrap';
import './admin.css'
import { useNavigate } from 'react-router-dom';
import { AdminInsight } from "./AdminInsight"

const OrderTable = ({ orders }) => {
    const navigate = useNavigate
    const [showUserModal, setShowUserModal] = useState(false);
    const [showOrderModal, setShowOrderModal] = useState(false);
    const [selectedOrder1, setSelectedOrder1] = useState(null);
    const [selectedOrder, setSelectedOrder] = useState(null);

    function handleCloseUserModal() {
        setShowUserModal(false);
    }

    function handleCloseOrderModal() {
        setSelectedOrder(null);
        setSelectedOrder1(null);
        setShowOrderModal(false);
    }

    function handleViewUserDetails(order) {
        setSelectedOrder1(order);
        setShowUserModal(true);
    }

    function handleViewOrderDetails(order) {
        setSelectedOrder(order);
        setShowOrderModal(true);
    }

    function convertToDateOnly(inputDate) {
        const date = new Date(inputDate);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();
        return `${day}-${month}-${year}`;
    }

    function convertToTimeOnly(inputDate) {
        const date = new Date(inputDate);
        const hour = date.getHours().toString().padStart(2, '0');
        const minute = date.getMinutes().toString().padStart(2, '0');
        const second = date.getSeconds().toString().padStart(2, '0');
        return `${hour}:${minute}:${second}`;
    }

    function setDeliveryStatus(_id, delivery) {
        axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/admin/set-delivery-status?userId=${localStorage.getItem('uid')}`, { _id, delivery }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }).then((res) => {
            toast.success(res.data.message)
            window.location.reload()
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else if (error.response.status === 404) {
                    toast.error(error.response.data.message);
                } else if (error.response.status === 401) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error(error.response.data.message);
                }
            } else if (error.request) {
                toast.error("No response received. Please check your network connection.");
            } else {
                toast.error("An error occurred. Please try again later.");
            }
        })
    }


    return (
        <table className="admin-order-table">
            <thead className='thead'>
                <tr className='admin-tr'>
                    <th className='th-first'>Date</th>
                    <th className='th'>Time</th>
                    <th className='th'>User Details</th>
                    <th className='th'>Order Details</th>
                    <th className='th'>Total</th>
                    <th className='th'>Status</th>
                    <th className='th-last'>Delivered</th>
                </tr>
            </thead>
            <tbody>
                {orders.map(order => (
                    <tr className='tr'>
                        <td className='td'>{convertToDateOnly(order.createdAt)}</td>
                        <td className='td'>{convertToTimeOnly(order.createdAt)}</td>
                        <td className='td'>
                            <button className='admin-btn' onClick={() => handleViewUserDetails(order)}>View</button>
                        </td>
                        <td className='td'>
                            <button className='admin-btn' onClick={() => handleViewOrderDetails(order)}>View</button>
                        </td>
                        <td className='td'>{order.total}</td>
                        <td className='td'>{order.delivery}</td>
                        <td className='td-main'>
                            <button className='admin-btn' onClick={() => setDeliveryStatus(order._id, "Delivered")}>Yes</button>
                            <button className='admin-btn' onClick={() => setDeliveryStatus(order._id, "Pending")}>No</button>
                        </td>
                    </tr>
                ))}
            </tbody>
            <Modal show={showUserModal} onHide={handleCloseUserModal}>
                <Modal.Header closeButton className='admin-modal-header'>
                    <Modal.Title>User Details</Modal.Title>
                </Modal.Header>
                <Modal.Body className='admin-modal-body'>
                    <p className='admin-p'>Name: <b>{selectedOrder1 && selectedOrder1.name}</b></p>
                    <p className='admin-p'>Email: <b>{selectedOrder1 && selectedOrder1.email}</b></p>
                    <p className='admin-p'>Phone: <b>{selectedOrder1 && selectedOrder1.phone}</b></p>
                    <p className='admin-p'>Address: <b>{selectedOrder1 && selectedOrder1.address.street}</b></p>
                    <p className='admin-p'>State: <b>{selectedOrder1 && selectedOrder1.address.state}</b></p>
                    <p className='admin-p'>City: <b>{selectedOrder1 && selectedOrder1.address.city}</b></p>
                    <p className='admin-p'>Pin: <b>{selectedOrder1 && selectedOrder1.address.pincode}</b></p>
                </Modal.Body>
            </Modal>

            <Modal show={showOrderModal} onHide={handleCloseOrderModal}>
                <Modal.Header closeButton className='admin-modal-header'>
                    <Modal.Title>Order Details</Modal.Title>
                </Modal.Header>
                <Modal.Body className='admin-modal-body'>
                    <p className='modal-p-1' >Category: {selectedOrder && selectedOrder.service && typeof selectedOrder.service != 'string' && selectedOrder.service.category}</p>
                    <p className='modal-p-1'>Clothes:</p>
                    <div className='admin-div'>
                        {selectedOrder && selectedOrder.service && typeof selectedOrder.service != 'string' && selectedOrder.service.clothes.map((cloth, index) => (
                            <div key={index} style={{ marginBottom: '15px' }}>
                                <p className='modal-p-2' >Name: {cloth.name}</p>
                                <p className='modal-p-3' >Price: {cloth.price}</p>
                                <p className='modal-p-3' >Quantity: {cloth.quantity}</p>
                            </div>
                        ))}
                    </div>
                    <p className='modal-p-4' >Total: {selectedOrder && selectedOrder.total}</p>
                </Modal.Body>
            </Modal>
        </table>
    );
};


const Admin = () => {

    const [orders, setOrders] = useState([])
    const [selectedDateTime, setSelectedDateTime] = useState({
        date: '',
        time: ''
    });

    const handleDateChange = (event) => {
        setSelectedDateTime({
            ...selectedDateTime,
            date: event.target.value
        });
    };

    const handleTimeChange = (event) => {
        setSelectedDateTime({
            ...selectedDateTime,
            time: event.target.value
        });
    };

    const handleCheckOrders = () => {
        const { date, time } = selectedDateTime;
        if (!date || !time) {
            toast.error("Please select date and time !")
        }
        else {
            const dateTimeToSend = `${date}T${time}:00.000Z`;
            axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/admin/filter-order?userId=${localStorage.getItem('uid')}`, { dateTime: dateTimeToSend }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            }).then((res) => {
                setOrders(res.data.orders)
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        toast.error(error.response.data.message);
                    } else if (error.response.status === 404) {
                        toast.error(error.response.data.message);
                    } else if (error.response.status === 401) {
                        toast.error(error.response.data.message);
                    } else {
                        toast.error(error.response.data.message);
                    }
                } else if (error.request) {
                    toast.error("No response received. Please check your network connection.");
                } else {
                    toast.error("An error occurred. Please try again later.");
                }
            })
        }
    };
    useEffect(() => {
        axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/admin/all-orders?userId=${localStorage.getItem('uid')}`, {}, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }).then((response) => {
            console.log(response.data.orders)
            setOrders(response.data.orders)
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else if (error.response.status === 404) {
                    toast.error(error.response.data.message);
                } else if (error.response.status === 401) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error(error.response.data.message);
                }
            } else if (error.request) {
                toast.error("No response received. Please check your network connection.");
            } else {
                toast.error("An error occurred. Please try again later.");
            }
        })
    }, [])

    const [data, setData] = useState("dashboard")

    const sideMenuBarHandler = (data) => {
        setData(data)
    }

    const [showModal, setShowModal] = useState(false);
    const [laundryId, setLaundryId] = useState("0001A")
    const [total, setTotal] = useState(0)

    const handleTotal = () => {
        axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/admin/total?userId=${localStorage.getItem('uid')}`, { laundryId }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        }).then((res) => {
            console.log(res, 123)
            setTotal(res.data.total)
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else if (error.response.status === 404) {
                    toast.error(error.response.data.message);
                } else if (error.response.status === 401) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error.response.data, 456)
                    toast.error(error.response.data.message);
                }
            } else if (error.request) {
                toast.error("No response received. Please check your network connection.");
            } else {
                toast.error("An error occurred. Please try again later.");
            }
        })

        setShowModal(true)
    }

    function handleCloseModal() {
        setShowModal(false);
    }

    const handleSelectChange = (event) => {
        const selectedLaundryId = event.target.value;
        setLaundryId(selectedLaundryId);
    };

    return (
        <div className="admin-panel-parent">
            <div className="admin-right-head">
                <div className="admin-right-head-logo"></div>
                <div className="admin-right-head-title">Sneaco.in</div>
            </div>
            <div className="admin-panel-container">
                <div className="admin-left">
                    <div className="admin-left-block" onClick={() => sideMenuBarHandler("laundary")}>
                        <div className="admin-left-block-logo1"></div>
                        <div className="admin-left-block-title">Laundary</div>
                    </div>
                    <div className="admin-left-block" onClick={() => sideMenuBarHandler("dashboard")}>
                        <div className="admin-left-block-logo"></div>
                        <div className="admin-left-block-title">Dashboard</div>
                    </div>
                </div>
                <div className="admin-right">
                    {
                        data === "laundary" ? (
                            <div className="admin-right-date-time">
                                <div className="date-time-row">
                                    <div className="date-picker">
                                        <label htmlFor="date">Date</label>
                                        <input type="date" id="date" name="date" onChange={handleDateChange} value={selectedDateTime.date} />
                                    </div>
                                    <div className="time-picker">
                                        <label htmlFor="time">Time</label>
                                        <input type="time" id="time" name="time" onChange={handleTimeChange} value={selectedDateTime.time} />
                                    </div>
                                    <button className="check-orders-button" onClick={handleCheckOrders}>Check Orders</button>
                                </div>
                                <div className="laundary-selection-out">
                                    <div className="laundary-selection">
                                        <label htmlFor="date">Laundaries</label>
                                        <select value={laundryId} onChange={handleSelectChange}>
                                            <option value="1">0001A</option>
                                            <option value="2">0001B</option>
                                        </select>
                                    </div>
                                    <button className="check-orders-button" onClick={handleTotal}>Sum(11 Days)</button>
                                    <>
                                        <Modal show={showModal} onHide={handleCloseModal}>
                                            <Modal.Header closeButton className='admin-modal-header'>
                                                <Modal.Title>Total of Past 11 days</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body className='admin-modal-body'>
                                                <bold>Rs {total}</bold>
                                            </Modal.Body>
                                        </Modal>
                                    </>
                                </div>
                            </div>
                        ) : (<></>)
                    }
                    <div className="admin-right-main-table">
                        {
                            data === "laundary" ? <OrderTable orders={orders} /> : <AdminInsight />
                        }

                    </div>
                </div>
                <ToastContainer />
            </div>
        </div>
    );
};

export default Admin;
