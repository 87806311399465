// PrivacyPolicy.js

import React from 'react';
import "./privacypolicy.css"
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const PrivacyPolicy = () => {
    return (
        <>
            <Navbar />
            <div className='div-pp'>
                <h2 className='h2-pp'>Privacy Policy</h2>
                <p className='p-pp'>
                    Your privacy is important to us. This Privacy Policy outlines how sneaco collects, uses, and protects your personal information.
                </p>
                <h3 className='h3-pp'>1. Information Collection</h3>
                <p className='p-pp'>
                    We collect information such as name, contact details, and location to provide and improve our services. Your information is securely stored and not shared with third parties.
                </p>
                <h3 className='h3-pp'>2. Cookies and Tracking</h3>
                <p className='p-pp'>
                    We use cookies to enhance your experience on our website. Cookies may also be used for tracking and analytics purposes. You can disable cookies in your browser settings.
                </p>
                <h3 className='h3-pp'>3. Data Security</h3>
                <p className='p-pp'>
                    We implement industry-standard security measures to protect your data from unauthorized access or disclosure.
                </p>
                {/* Add more sections as needed */}
            </div>
            <Footer />
        </>
    );
};

export default PrivacyPolicy;
