import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-cards';
import { EffectCards, Navigation } from 'swiper/modules';
import jaipur from "../../images/jaipur.webp";
import delhi from "../../images/delhi.webp";
import mumbai from "../../images/mumbai.webp";
import gurgaon from "../../images/gurgaon3.jpeg"
import "./Cities.css"

export const Cities = () => {
    return (
        <div>
            <section id="cities" className="service-coming-soon">
                <div className="service-coming-soon-head">Cities you'll find us in . . .</div>
                <div className="service-to-your-home-ins-data-data">We are available in 9 areas across 3 cities to serve you the fastest laundry of India.</div>
                <div className="cities-serving">
                    <Swiper
                        slidesPerView={1}
                        className="swiper"
                        navigation={true}
                        effect={'cards'}
                        grabCursor={true}
                        modules={[EffectCards, Navigation]}
                    >
                        <SwiperSlide>
                            <img src={jaipur} alt="jaipur" className="cities-serving-ins" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={delhi} alt="delhi" className="cities-serving-ins" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={mumbai} alt="mumbai" className="cities-serving-ins" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={gurgaon} alt="gurgaon" className="cities-serving-ins" />
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>
        </div>
    )
}
