import React, { useEffect } from 'react';
import { getAuth, signOut } from "firebase/auth"
import { useState } from 'react';
import { Navbar as BootstrapNavbar, Nav } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css';

export default function Navbar() {
  const [token, setToken] = useState(null)
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("token")) {
      setToken(localStorage.getItem("token"))
    }
  }, [])
  const handleLogout = () => {
    const auth = getAuth()
    const dummy = signOut(auth).then(() => {
      localStorage.removeItem('token');
      localStorage.removeItem('uid');
      localStorage.removeItem('vendorId')
      localStorage.removeItem('laundryId')
      setToken(null);
      navigate("/");
      alert("You are successfully logout !");
    })
    return dummy
  }

  return (
    <BootstrapNavbar expand="lg" style={{ padding: 0 }}>
      <div className='container-fluid'>
        <Link className="navbar-brand logo" to="/"
          style={{
            fontFamily: 'Anton, sans-serif',
            fontSize: '27px',
            color: 'white'
          }}
        >Sneaco.in</Link>
        <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
        <BootstrapNavbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            {token ?
              <Link className="nav-link mx-2 link" to="/myorders">My orders</Link>
              : ""}
            <a className="nav-link mx-2 link" style={{ cursor: "pointer" }} href="/#services">Services</a>
            <a className="nav-link mx-2 link" style={{ cursor: "pointer" }} href="#footer">Connect</a>
            <Link className="nav-link mx-2 link" to="/about">About us</Link>
            {token ?
              <Link className="nav-link mx-2 link" to="/profile">Profile</Link>
              : ""}
          </Nav>
          {(!token) ?
            <div className='ml-auto'>
              <Link className="btn bg-white text-success mx-2 link" to="/signup">Sign in/Sign up</Link>
            </div>
            :
            <div>
              <Link className="btn bg-white text-danger mx-2 link" onClick={handleLogout}>Log out</Link>
            </div>
          }
        </BootstrapNavbar.Collapse>
      </div>
    </BootstrapNavbar>
  );
}
