import React, { useState, useEffect } from "react";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "./checkout.css";
import { Container, Table, Button } from "react-bootstrap";
import { useNavigate } from "react-router";


const CheckoutPopup = ({ onClose, onSubmit, onSubmit1, user }) => {

  const [formData, setFormData] = useState({
    name: user.name,
    email: user.email,
    phone: user.contact,
    address: user.address,
    city: user.city,
    state: user.state,
    pincode: user.pincode,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = () => {
    onSubmit(formData);
  };

  const handleSubmit1 = () => {
    onSubmit1(formData)
  }

  return (
    <div className="checkout-popup">
      <div className="popup-content">
        <span className="close-btn" onClick={onClose}>&times;</span>
        <h2>Checkout Details</h2>
        <label>Name</label>
        <input type="text" name="name" value={formData.name} onChange={handleChange} />

        <label>Email</label>
        <input type="email" name="email" value={formData.email} onChange={handleChange} />

        <label>Phone</label>
        <input type="tel" name="phone" value={formData.phone} onChange={handleChange} />

        <label>Address</label>
        <input type="text" name="address" value={formData.address} onChange={handleChange} />

        <label>City</label>
        <input type="text" name="city" value={formData.city} onChange={handleChange} />

        <label>State</label>
        <input type="text" name="state" value={formData.state} onChange={handleChange} />

        <label>Pincode</label>
        <input type="text" name="pincode" value={formData.pincode} onChange={handleChange} />

        <div className="btn-container">
          <button className="cod-btn" onClick={handleSubmit}>Cash on Delivery</button>
          <button className="cod-btn" onClick={handleSubmit1}>Pay Online</button>
        </div>
      </div>
    </div>
  );
}


export default function Checkout() {
  const navigate = useNavigate()
  const [cart, setCart] = useState([]);

  const [userId, setUserId] = useState(null);

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const [user, setUser] = useState({})
  useEffect(() => {
    try {
      axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/user/get-user?userId=${localStorage.getItem('uid')}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then((res) => {
        setUser(res.data.user[0])
      }).catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            toast.error(error.response.data.message);
          } else if (error.response.status === 404) {
            toast.error(error.response.data.message);
          } else if (error.response.status === 401) {
            toast.error(error.response.data.message);
          } else {
            toast.error(error.response.data.message);
          }
        } else if (error.request) {
          toast.error("No response received. Please check your network connection.");
        } else {
          toast.error("An error occurred. Please try again later.");
        }
      })
    }
    catch (error) {
      toast.error("An error occurred. Please try again later.")
    }
  }, [])

  const handleCheckout = async (formData) => {
    try {
      await axios
        .post(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/order/add-order`, {
          cart,
          formData,
          userId: `${localStorage.getItem("uid")}`
        }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          }
        })
        .then((res) => {
          toast.success(res.data.message)
          closePopup()
          setTimeout(() => {
            navigate("/paymentsuccess");
          }, 1000);
        })
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
        } else if (error.response.status === 404) {
          toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
        } else if (error.response.status === 401) {
          toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
        } else {
          toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
        }
      } else if (error.request) {
        toast.error("No response received. Please check your network connection.", { position: toast.POSITION_TOP_RIGHT });
      } else {
        toast.error("An error occurred. Please try again later.", { position: toast.POSITION_TOP_RIGHT });
      }
      closePopup()
    }
  };

  useEffect(() => {
    const uid = localStorage.getItem("uid");
    if (uid) {
      setUserId(uid);
    }
  }, []);


  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/service/get-services`,
        { userId: `${localStorage.getItem("uid")}` },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        // console.log(response.data.services)
        setCart(response.data.services);
        // console.log("cart: ", cart, cart.length);
        // console.log("rds: ", response.data.services);
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          if (error.response.status === 400) {
            toast.error(error.response.data.message);
          } else if (error.response.status === 500) {
            toast.error(error.response.data.message);
          } else if (error.response.status === 401) {
            toast.error(error.response.data.message);
          }
          else {
            alert(error.response.data.message);
          }
        } else if (error.request) {
          toast.error("No response received. Please check your network connection.");
        } else {
          toast.error("An error occurred. Please try again later.");
        }
      });
  },[cart]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const cartData = queryParams.get("cart");
    if (cartData) {
      // Parse the cart data from the query parameter (modify based on your cart structure)
      const parsedCart = JSON.parse(decodeURIComponent(cartData));
      setCart(parsedCart);
    }
  }, []);


  const calculateTotalCartValue = () => {
    let total = 0;
    cart.forEach((item) => {
      total += item.total;
    });
    return total;
  };

  const checkoutHandler = async (formData) => {
    try {
      let total = 0;
      cart.map((item)=>{
        total += item.total;
      })
      await axios
        .post(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/payment/indirect-payment`, {
          cart,
          formData,
          userId: `${localStorage.getItem("uid")}`,
          amount: total,
          number: '7498608775',
          name:formData.name,
          MUID: "MUID" + Date.now(),
          transactionId: 'T' + Date.now(),
        })
        .then((res) => {
          console.log(res);
          window.location.href = res.data.data
          localStorage.clear();
        });
    } catch (error) {
      console.log(error)
      if (error.response) {
        if (error.response.status === 400) {
          toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
        } else if (error.response.status === 404) {
          toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
        } else if (error.response.status === 401) {
          toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
        } else {
          toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
        }
      } else if (error.request) {
        toast.error("No response received. Please check your network connection.", { position: toast.POSITION_TOP_RIGHT });
      } else {
        toast.error("An error occurred. Please try again later.", { position: toast.POSITION_TOP_RIGHT });
      }
    }
  };

  const handleDelete = (_id) => {
    let clothesLength;
    cart.forEach((item)=>{
      clothesLength = item.service.clothes.length;
    })
    var basketCount = parseInt(localStorage.getItem('basketCount'));
    basketCount -= clothesLength;
    if (basketCount < 0) {
      basketCount = 0;
    }
    localStorage.setItem('basketCount', basketCount); 
    // console.log(cart);
    
    axios.delete(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/service/delete-service?_id=${_id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => {
      toast.success(res.data.message)
    }).catch((error) => {
      console.log(error);
      if (error.response) {
        if (error.response.status === 400) {
          toast.error(error.response.data.message);
        } else if (error.response.status === 500) {
          toast.error(error.response.data.message);
        } else if (error.response.status === 401) {
          toast.error(error.response.data.message);
        }
        else {
          alert(error.response.data.message);
        }
      } else if (error.request) {
        toast.error("No response received. Please check your network connection.");
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    });
  }

  return (
    <div>
      <Navbar />
      <Container>
        <div className="div-10">
          <div className="div-11">Bucket.</div>
          <div className="div-12">fill up your basket.</div>
        </div>

        {cart.length > 0 ? (
          <Table className="custom-table" bordered hover>
            <thead>
              <tr>
                <th style={{backgroundColor: "#ffde59", color:"black"}}>Service</th>
                <th style={{backgroundColor: "#ebedee", color:"black"}}>Cloth</th>
                <th style={{backgroundColor: "#ebedee", color:"black"}}>Price</th>
                <th style={{backgroundColor: "#ebedee", color:"black"}}>Quantity</th>
                <th style={{backgroundColor: "#ebedee", color:"black"}}>Total Price</th>
                <th style={{backgroundColor: "#ebedee", color:"black"}}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {cart.map((item, index) => (
                <React.Fragment key={index}>
                  {item.service.clothes.map((cloth, i) => (
                    <tr key={i}>
                      {i === 0 ? (
                        <td rowSpan={item.service.clothes.length}>
                          {item.service.category.toUpperCase()}
                        </td>
                      ) : null}
                      <td>{cloth.name}</td>
                      <td>Rs {cloth.price}</td>
                      <td>{cloth.quantity}</td>
                      {i === 0 ? (
                        <td rowSpan={item.service.clothes.length}>
                          {item.total}
                        </td>
                      ) : null}
                      {i === 0 ? (
                        <td rowSpan={item.service.clothes.length}>
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => handleDelete(item._id)}
                            style={{ backgroundColor: "#00F39F", border: "1px solid #00F39F", color: "black" }}
                          >
                            Delete
                          </button>
                        </td>
                      ) : null}
                    </tr>
                  ))}
                </React.Fragment>
              ))}  
            <tr>
              <td colSpan="4">Total Cart Value:</td>
              <td colSpan="2">Rs. {calculateTotalCartValue()}</td>
            </tr>
            <tr>
              <td colSpan="3">Platform Fee:</td>
              <td colSpan="1"> + </td>
              <td colSpan="2">Rs. 3</td>
            </tr>
            <tr>
              <td colSpan="4">Total Amount (Including Platform Fee):</td>
              <td colSpan="2">Rs. {calculateTotalCartValue() + 3}</td>
            </tr>
          </tbody>
          </Table>
         
        ) : (
          <p>Your cart is empty.</p>
        )}
        {
          cart.length > 0 ? <Button onClick={openPopup} style={{ backgroundColor: "#00F39F", border: "1px solid #00F39F", color: "black" }} >Checkout</Button> : ""
          // cart.length > 0 ? <Button onClick={checkoutHandler} style={{ backgroundColor: "#00F39F", border: "1px solid #00F39F", color: "black" }} >Checkout</Button> : ""
        }
        {isPopupOpen && (
          <CheckoutPopup onClose={closePopup} onSubmit={handleCheckout} onSubmit1={checkoutHandler} user={user} />
        )}
      </Container>
      <Footer />
      <ToastContainer />
    </div>
  );
}



// const handleQuantityChange = (_id, itemIndex, newQuantity) => {
  //   if (newQuantity < 1) {
  //     axios.delete(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/service/delete-service?_id=${_id}`, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       },
  //     }).then((response) => { toast.success(response.data.message, { position: toast.POSITION_TOP_RIGHT }) }).catch((error) => {
  //       if (error.response) {
  //         if (error.response.status === 400) {
  //           toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
  //         } else if (error.response.status === 404) {
  //           toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
  //         } else if (error.response.status === 401) {
  //           toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
  //         } else {
  //           toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
  //         }
  //       } else if (error.request) {
  //         toast.error("No response received. Please check your network connection.", { position: toast.POSITION_TOP_RIGHT });
  //       } else {
  //         toast.error("An error occurred. Please try again later.", { position: toast.POSITION_TOP_RIGHT });
  //       }
  //     })
  //     return;
  //   }
  //   setCart((prevCart) => {
  //     const updatedCart = [...prevCart];
  //     updatedCart[itemIndex].quantity = newQuantity;
  //     return updatedCart;
  //   });
  //   axios.patch(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/service/update-service`, { quantity: newQuantity, _id }, {
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("token")}`,
  //     },
  //   }).then((response) => { console.log(response) }).catch((error) => { console.log(error) })
  // }

  // const formatCartDetails = () => {
  //   const cartDetails = cart.map((item) => {
  //     const { category, clothes } = item.service;
  //     const clothesDetails = clothes.map((cloth) => {
  //       return `${cloth.name}: ${cloth.quantity} x ${cloth.price}`;
  //     })
  //     return `${category} - ${clothesDetails.join(', ')} - Total: ${item.total}`;
  //   });
  //   const cartMessage = cartDetails.join('\n');
  //   const userMessage = `User Details:\nName: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nAddress: ${formData.address}, ${formData.city}, ${formData.state}, ${formData.pincode}`;
  //   return `${cartMessage}\n\n${userMessage}`;
  // }
  // // console.log(formatCartDetails(), 1123)
  // const formattedMessage = encodeURIComponent(formatCartDetails());
  // const whatsappLink = `https://api.whatsapp.com/send?phone=8290023272&text=${formattedMessage}`
  // // window.location.href = whatsappLink
  // // window.open(whatsappLink, '_blank');
  // const link = document.createElement('a');
  // link.href = whatsappLink;
  // link.target = '_blank'; 
  // link.click();