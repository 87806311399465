import React from 'react'
import towel from "../../images/towels.webp";
import airforce from "../../images/air-force.webp";
import car from "../../images/car.webp";
import clothes from "../../images/clothes.webp"
import "./Services.css"

export const Services = () => {
    return (
        <div>
            <section id='services' className="service-to-your-home">
                <div className="service-to-your-home-ins-data">
                    <div className="service-to-your-home-ins-data-head">Service to your home.</div>
                    <div className="service-to-your-home-ins-data-data">All we give is all you need. The cycle of process to save your time and effort is here.</div>
                </div>
                <div className="service-to-your-home-ins-pic">
                    <div className="service-to-your-home-ins-pic-each" style={{ backgroundImage: "linear-gradient(to bottom right ,rgb(158, 158, 158) , rgb(255, 255, 255))" }}>
                        <div className="service-to-your-home-ins-pic-each-head">Dry Cleaning, All Clothes, All Seasons</div>
                        <div className="service-to-your-home-ins-pic-each-data">Cotton, Woolen, Silk, Jackets, Blankets Everything you can keep inside your homes.</div>
                        <img className="service-to-your-home-ins-pic-each-pic" alt="towel" src={towel} ></img>
                    </div>
                    <div className="service-to-your-home-ins-pic-each" style={{ backgroundImage: "linear-gradient(to bottom left ,  #00F39F , rgb(255, 255, 255))" }}>
                        <div className="service-to-your-home-ins-pic-each-head">Shoe Laundry & Restoration</div>
                        <div className="service-to-your-home-ins-pic-each-data">Get Shoes Washed, Drycleaned & Restore. Get them delivered to your home in One-Day.</div>
                        <img className="service-to-your-home-ins-pic-each-pic" alt="airforce" src={airforce} ></img>
                    </div>
                    <div className="service-to-your-home-ins-pic-each" style={{ backgroundImage: "linear-gradient(to top right , rgb(171, 174, 173) , rgb(255, 255, 255))" }}>
                        <div className="service-to-your-home-ins-pic-each-head">Cars, Washing & Servicing</div>
                        <div className="service-to-your-home-ins-pic-each-data">Get Shoes Washed, Drycleaned & Restore. Get them delivered to your home in One-Day.</div>
                        <img className="service-to-your-home-ins-pic-each-pic" alt="car" src={car} ></img>
                    </div>
                    <div className="service-to-your-home-ins-pic-each" style={{ backgroundImage: "linear-gradient(to top left , rgb(171, 174, 173) , rgb(255, 255, 255))" }}>
                        <div className="service-to-your-home-ins-pic-each-head">Clothes Washing & Iron.</div>
                        <div className="service-to-your-home-ins-pic-each-data">Your Clothes are piling up? yeah they will because u didn't give them to us. We wash your clothes.</div>
                        <img className="service-to-your-home-ins-pic-each-pic" alt="clothes" src={clothes} ></img>
                    </div>
                </div>
            </section>
        </div>
    )
}
