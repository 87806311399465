import "./vendor-main.css"
import 'react-datepicker/dist/react-datepicker.css';
import { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { Accordian } from "./Accordian";
import { Modal } from 'react-bootstrap';
import { NotFound } from "../../components/NotFound.jsx"
import DatePicker from 'react-datepicker';
import axios from "axios"

export function VendorMain() {
    const [showLeftPanel, setShowLeftPanel] = useState(true);
    const [orderStatus, setOrderStatus] = useState("Active")
    const [display, setDisplay] = useState(false)
    const [orders, setOrders] = useState()
    useEffect(() => {
        if (localStorage.getItem('vendorId')) {
            setDisplay(true)
        }
    })
    useEffect(() => {
        if (orderStatus == "Active") {
            axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/vendor/all-orders?userId=${localStorage.getItem('uid')}`, { laundryId: localStorage.getItem("laundryId"), delivery: orderStatus }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                }
            }).then((res) => {
                console.log(res)
                setOrders(res.data.orders)
            }).catch((error) => {
                console.log(error)
                if (error.response) {
                    if (error.response.status === 400) {
                        toast.error(error.response.data.message);
                    } else if (error.response.status === 404) {
                        toast.error(error.response.data.message);
                    } else if (error.response.status === 401) {
                        toast.error(error.response.data.message);
                    } else {
                        console.log(error.response.data, 456)
                        toast.error(error.response.data.message);
                    }
                } else if (error.request) {
                    toast.error("No response received. Please check your network connection.");
                } else {
                    toast.error("An error occurred. Please try again later.");
                }
            })
        }
        else {
            axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/vendor/all-orders?userId=${localStorage.getItem('uid')}`, { laundryId: localStorage.getItem("laundryId"), delivery: orderStatus }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                }
            }).then((res) => {
                console.log(res.data.orders, 123)
                setOrders(res.data.orders)
            }).catch((error) => {
                console.log(error)
                if (error.response) {
                    if (error.response.status === 400) {
                        toast.error(error.response.data.message);
                    } else if (error.response.status === 404) {
                        toast.error(error.response.data.message);
                    } else if (error.response.status === 401) {
                        toast.error(error.response.data.message);
                    } else {
                        console.log(error.response.data, 456)
                        toast.error(error.response.data.message);
                    }
                } else if (error.request) {
                    toast.error("No response received. Please check your network connection.");
                } else {
                    toast.error("An error occurred. Please try again later.");
                }
            })
        }
    }, [orderStatus])

    useEffect(() => {
        const handleResize = () => {
            setShowLeftPanel(window.innerWidth > 800);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [laundryName, setLaundryName] = useState("")
    const [laundryId, setLaundryId] = useState("")

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/vendor/laundry-details?userId=${localStorage.getItem('uid')}`, { laundryId: localStorage.getItem("laundryId") }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        }).then((res) => {
            console.log(res)
            setLaundryId(res.data.id)
            setLaundryName(res.data.name)
        }).catch((error) => {
            console.log(error)
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else if (error.response.status === 404) {
                    toast.error(error.response.data.message);
                } else if (error.response.status === 401) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error.response.data, 456)
                    toast.error(error.response.data.message);
                }
            } else if (error.request) {
                toast.error("No response received. Please check your network connection.");
            } else {
                toast.error("An error occurred. Please try again later.");
            }
        })
    })

    const [selectedDate, setSelectedDate] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const handleDateChange = (date) => {
        axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/vendor/all-orders?userId=${localStorage.getItem('uid')}`, { laundryId: "0001A", delivery: orderStatus, createdAt: date }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        }).then((res) => {
            console.log(res.data.orders)
            setOrders(res.data.orders)
        }).catch((error) => {
            console.log(error)
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else if (error.response.status === 404) {
                    toast.error(error.response.data.message);
                } else if (error.response.status === 401) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error.response.data, 456)
                    toast.error(error.response.data.message);
                }
            } else if (error.request) {
                toast.error("No response received. Please check your network connection.");
            } else {
                toast.error("An error occurred. Please try again later.");
            }
        })
    };

    const handleCloseModal = () => {
        setModalIsOpen(false);
    };

    if (display) {
        return (
            <div className="vendor-panel-main">
                <div className="vendor-panel-navbar">
                    <div className="vendor-panel-navbar-left">
                        <div className="vendor-panel-navbar-left1">
                            <div className="vendor-panel-navbar-left1-ins1">{laundryName}</div>
                            <div className="vendor-panel-navbar-left1-ins2"><bold>Unique Id</bold> - {laundryId}</div>
                        </div>
                        <div className="vendor-panel-navbar-left2"></div>
                    </div>
                    <div className="vendor-panel-navbar-icon" onClick={() => { window.location.href = "/" }}></div>
                </div>
                <div className="vendor-panel-body">
                    {
                        showLeftPanel ? (
                            <div className="vendor-panel-body-left">
                                <div className={orderStatus == "Active" ? "vendor-panel-body-left-ins-active" : "vendor-panel-body-left-ins"} onClick={() => setOrderStatus("Active")}>Active Orders</div>
                                <div className={orderStatus == "Delivered" ? "vendor-panel-body-left-ins-active" : "vendor-panel-body-left-ins"} onClick={() => setOrderStatus("Delivered")}>Completed Orders</div>
                            </div>
                        ) : (
                            <div className="vendor-panel-body-left-short">
                                <div className={orderStatus == "Active" ? "vendor-panel-body-left-ins-short-active" : "vendor-panel-body-left-ins-short"} onClick={() => setOrderStatus("Active")}>A</div>
                                <div className={orderStatus == "Delivered" ? "vendor-panel-body-left-ins-short-active" : "vendor-panel-body-left-ins-short"} onClick={() => setOrderStatus("Delivered")}>C</div>
                            </div>
                        )
                    }
                    <div className="vendor-panel-body-right">
                        <div className="vendor-panel-body-right-head">
                            <div className="vendor-panel-body-right-head-ins1">Orders.</div>
                            <div className="vendor-panel-body-right-head-ins2" onClick={() => setModalIsOpen(true)}>Date</div>
                            <Modal show={modalIsOpen} onHide={handleCloseModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Select Date</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <DatePicker
                                        selected={selectedDate}
                                        onChange={(date) => {
                                            handleDateChange(date);
                                            handleCloseModal();
                                        }}
                                        inline
                                    />
                                </Modal.Body>
                            </Modal>
                        </div>
                        <div className="vendor-panel-body-right-body">
                            <div className="vendor-panel-body-right-body-head">
                                <div className="vendor-panel-body-right-body-ins0">Customer Name</div>
                                <div className="vendor-panel-body-right-body-ins">Service Name</div>
                                <div className="vendor-panel-body-right-body-ins">Service Status</div>
                                <div className="vendor-panel-body-right-body-ins1"></div>
                            </div>
                            {
                                orders && orders.length > 0 && <Accordian orders={orders} />
                            }
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        )
    }
    else {
        return (
            <NotFound />
        )
    }
}