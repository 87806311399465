import "./customersupport.css"
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import React from 'react';

const CustomerSupport = () => {
    return (
        <>
            <Navbar />
            <div className="cs-div">
                <h2 className="cs-h2">Customer Support</h2>
                <p className="cs-p">
                    Our customer support team is dedicated to assisting you. If you have any questions, issues, or feedback, feel free to contact us through the following channels:
                </p>
                <ul className="cs-ul">
                    <li className="cs-li">Email: sneaco.operatives@gmail.com</li>
                    <li className="cs-li">Phone: 8290023272</li>
                    <li className="cs-li">Live Chat: Available on our website</li>
                </ul>
                <h3 className="cs-h3">Business Hours</h3>
                <p className="cs-p">
                    Our customer support operates during regular business hours, (9 a.m. to 8 p.m.). Responses to inquiries outside these hours may be delayed.
                </p>
            </div>
            <Footer />
        </>
    );
};

export default CustomerSupport;
