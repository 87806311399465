import React, { useState, useEffect } from "react";
import { Form, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import {Link} from "react-router-dom"
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router";
import Navbar from "./Navbar";
import Footer from "./Footer";
import axios from "axios"
import "./Profile.css";

export function Profile({ setDisplay, user }) {

  const navigate = useNavigate()
  const [userId, setUserId] = useState(null)
  const [token, setToken] = useState(null)
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    name: user.name,
    contact: user.contact,
    email: user.email,
    age: user.age,
    gender: 'Male',
    address: user.address,
    city: user.city,
    state: user.state,
    pincode: user.pincode,
    isStudent: "student",
  })

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setShow(true);
      setToken(token)
      setUserId(localStorage.getItem("uid"))
    }
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/user/update-user`,
        { formData, userId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Your details have been updated");
      } else {
        toast.error("An unexpected error occurred. Please try again later.");
      }
      setDisplay(true)
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          toast.error(error.response.data.message);
        } else if (error.response.status === 404) {
          toast.error(error.response.data.message);
        } else if (error.response.status === 401) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.response.data.message);
        }
      } else if (error.request) {
        toast.error("No response received. Please check your network connection.");
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    }
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Your role (e.g., student, working, etc.)
    </Tooltip>
  );

  if (show) {
    return (
      <div>
        <Navbar />
        <div className="profile">
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formFirstName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                autoFocus
              />
            </Form.Group>

            <Form.Group controlId="formLastName">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formLastName">
              <Form.Label>Contact</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter contact details"
                name="contact"
                value={formData.contact}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formAge">
              <Form.Label>Age</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter age"
                name="age"
                value={formData.age}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formGender">
              <Form.Label>Gender</Form.Label>
              <Form.Control
                as="select"
                name="gender"
                value={formData.gender}
                onChange={handleChange}
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formAddress">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter address"
                name="address"
                value={formData.address}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formCity">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter city"
                name="city"
                value={formData.city}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formState">
              <Form.Label>State</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter state"
                name="state"
                value={formData.state}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formPincode">
              <Form.Label>Pincode</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter pincode"
                name="pincode"
                value={formData.pincode}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formRole">
              <Form.Label>Role</Form.Label>
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <Form.Control
                  as="select"
                  name="isStudent"
                  value={formData.isStudent}
                  onChange={handleChange}
                >
                  <option value="student">Student</option>
                  <option value="working">Working</option>
                  <option value="other">Other</option>
                </Form.Control>
              </OverlayTrigger>
            </Form.Group>

            <Button
              variant="primary"
              type="submit"
              disabled={!formData.name || !formData.address}
            >
              Submit
            </Button>
            <Button
              variant="primary"
              type="submit"
              style={{ marginLeft: "10px" }}
              onClick={() => setDisplay(true)}
            >
              Cancel
            </Button>
          </Form>
        </div>
        <Footer />
        <ToastContainer />
      </div>
    );
  } else {
    return (
      <>
        <Navbar />
        <div className="profile">Please log in!</div>
        <Footer />
        <ToastContainer />
      </>
    )
  }
}


export function NewProfile() {

  const [display, setDisplay] = useState(true)
  const [show, setShow] = useState(false)
  const [user, setUser] = useState({})

  const displayHandler = () => {
    setDisplay(false)
  }

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setShow(true)
    }
  })

  useEffect(() => {
    try {
      axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/user/get-user?userId=${localStorage.getItem('uid')}`).then((res) => {
        // console.log(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/user/get-user?userId=${localStorage.getItem('uid')}`)
        // console.log(res)
        // console.log(res.data.user[0])
        setUser(res.data.user[0])
      }).catch((error) => {
        console.log(error)
        if (error.response) {
          if (error.response.status === 400) {
            toast.error(error.response.data.message);
          } else if (error.response.status === 404) {
            toast.error(error.response.data.message);
          } else if (error.response.status === 401) {
            toast.error(error.response.data.message);
          } else {
            toast.error(error.response.data.message);
          }
        } else if (error.request) {
          toast.error("No response received. Please check your network connection.");
        } else {
          toast.error("An error occurred. Please try again later.");
        }
      })
    }
    catch (error) {
      console.log(error)
      if (error.response) {
        if (error.response.status === 400) {
          toast.error(error.response.data.message);
        } else if (error.response.status === 404) {
          toast.error(error.response.data.message);
        } else if (error.response.status === 401) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.response.data.message);
        }
      } else if (error.request) {
        toast.error("No response received. Please check your network connection.");
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    }
  })

  const [selected, setSelected] = useState('student');

  const handleClick = (index) => {
    setSelected(index);
  };

  if (show) {
    if (display) {
      return (
        <div className="new-profile">
          <Navbar />
          <div className="new-profile-head">YOUR PROFILE</div>
          <div className="new-profile-main-main">
            <div className="new-profile-main1">
              <div className="new-profile-main-left">
                <div className="new-profile-main-head">
                  <svg onClick={displayHandler} style={{ margin: "20px", cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" fill="#ffd900" /></svg>
                </div>
                <div className="new-profile-main-data">
                  <div className="new-profile-main-data1">FULL NAME</div>
                  <div className="new-profile-main-data2">
                    {
                      user.name ? `${user.name}` : "Update It"
                    }
                  </div>
                </div>
                <div className="new-profile-main-data">
                  <div className="new-profile-main-data1">EMAIL</div>
                  <div className="new-profile-main-data2">
                    {
                      user.email ? `${user.email}` : "Update It"
                    }
                  </div>
                </div>
                <div className="new-profile-main-data">
                  <div className="new-profile-main-data1">CONTACT</div>
                  <div className="new-profile-main-data2">
                    {
                      user.contact ? `${user.contact}` : "Update It"
                    }
                  </div>
                </div>
                <div className="new-profile-main-data">
                  <div className="new-profile-main-data1">GENDER</div>
                  <div className="new-profile-main-data2">
                    {
                      user.gender ? `${user.gender}` : "Update It"
                    }
                  </div>
                </div>
                <div className="new-profile-main-data">
                  <div className="new-profile-main-data1">AGE</div>
                  <div className="new-profile-main-data2">
                    {
                      user.age ? `${user.age}` : "Update It"
                    }
                  </div>
                </div>
                <div className="new-profile-main-data">
                  <div className="new-profile-main-data1">STUDENT</div>
                  <div className="new-profile-main-data2">
                    {
                      user.isStudent ? (
                        <div className="bullet-list">
                          {['student', 'working', 'other'].map((item, index) => (
                            <div className="bullet-main" key={index} onClick={() => handleClick(index)}>
                              <div className={`bullet ${selected === index ? 'selected' : ''}`}></div>
                              <div
                              >
                                {item}
                              </div>
                            </div>
                          ))}
                        </div >
                      ) : "Update It"
                    }
                  </div>
                </div>
              </div>
              <div className="new-profile-main-right">
                <svg className="profile-svg1" xmlns="http://www.w3.org/2000/svg" height="96" width="94" viewBox="0 0 448 512">
                  <circle cx="224" cy="256" r="424" fill="#00F39F" />
                  <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" fill="black" />
                </svg>
                <svg className="profile-svg2" xmlns="http://www.w3.org/2000/svg" height="76" width="74" viewBox="0 0 448 512">
                  <circle cx="224" cy="256" r="324" fill="#00F39F" />
                  <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" fill="black" />
                </svg>
              </div>
            </div>
            <div className="new-profile-main2">
              <div className="new-profile-main-head">
                <svg onClick={displayHandler} style={{ margin: "20px", cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" fill="#ffd900" /></svg>
              </div>
              <div className="new-profile-main-data">
                <div className="new-profile-main-data1">ADDRESS</div>
                <div className="new-profile-main-data2">
                  {
                    user.address ? `${user.address}` : "Update It"
                  }
                </div>
              </div>
              <div className="new-profile-main-data">
                <div className="new-profile-main-data1">STATE</div>
                <div className="new-profile-main-data2">
                  {
                    user.state ? `${user.state}` : "Update It"
                  }
                </div>
              </div>
              <div className="new-profile-main-data">
                <div className="new-profile-main-data1">CITY</div>
                <div className="new-profile-main-data2">
                  {
                    user.city ? `${user.city}` : "Update It"
                  }
                </div>
              </div>
              <div className="new-profile-main-data">
                {
                  user.role === "vendor" || user.role === "admin" ? (<Link to="/vendor" style={{color:"black", textDecoration:"none"}} className="new-profile-main-data1">View Vendor Panel</Link>) : ""
                }
              </div>
              <div className="new-profile-main-data">
                {
                  user.role === "admin" ? (<Link to="/admin" style={{color:"black", textDecoration:"none"}} className="new-profile-main-data1">View Admin Panel</Link>) : ""
                }
              </div>
            </div>
          </div>
          <Footer />
          <ToastContainer />
        </div>
      )
    }
    else {
      return (<Profile setDisplay={setDisplay} user={user} />)
    }
  }
  else {
    return (
      <>
        <Navbar />
        <div className="profile">Please log in!</div>
        <Footer />
        <ToastContainer />
      </>
    )
  }
}